<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h2 class="heading-title">VENTURE BUILDERS</h2>
            <p class="wow fadeInUp" data-wow-delay="300ms">
              {{ $t('simoniniSoftware') }}
              <br /><br />
              {{ $t('creativityAndSimplicity') }}
              <br /><br />
              {{ $t('moreThanSoftware') }}
            </p>
            <!-- <div class="purchase-btn">
              <router-link class="btn-transparent" to="/"
                >PURCHASE IMROZ</router-link
              >
            </div> -->
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        icon: "send",
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<template>
  <v-row class="mt--20">
    <v-col cols="12">
      <ul class="brand-style-2">
        <li v-for="(brand, i) in brandImages" :key="i">
          <a :href="brand.url" target="_blank">
            <img :src="brand.src" alt="Logo Images" />
          </a>
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        brandImages: [
          {
            src: require("../../assets/images/brand/qs.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/bradesco.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/msd.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/fleury.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/qs-2.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/qs-4.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/vivo.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/algar_nb.png"),
            url: "#",
          },
        ],
      };
    },
  };
</script>
